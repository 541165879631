import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ACBCRRoutingModule } from './acbcr-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import {
  NgbDropdownModule,
  NgbDatepickerModule,
  NgbAlertModule,
  NgbPaginationModule,
  NgbTypeaheadModule,
  NgbTooltipModule,
  NgbModalModule,
} from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { StoreModule, ActionReducer } from '@ngrx/store';
import { NgSelectModule } from '@ng-select/ng-select';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgChartsModule } from 'ng2-charts';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { ContainerComponent } from './components/container/container.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FooterComponent } from './components/dashboard/footer/footer.component';
import { SidebarComponent } from './components/dashboard/sidebar/sidebar.component';
import { TopbarComponent } from './components/dashboard/topbar/topbar.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { HoldingsComponent } from './components/pages/holdings/holdings.component';
import { AnimalsComponent } from './components/pages/animals/animals.component';
import { AllowancesComponent } from './components/pages/allowances/allowances.component';
import { ContractsComponent } from './components/pages/contracts/contracts.component';
import { AddUpdateHoldingComponent } from './components/pages/holdings/components/add-update-holding/add-update-holding.component';
import { AddUpdateAnimalComponent } from './components/pages/animals/components/add-update-animal/add-update-animal.component';

import { ChangeStatusReasonComponent } from './components/pages/holdings/components/add-update-holding/form-blocks/identificare-form-block/change-status-reason/change-status-reason.component';
import { FacturiChitanteSettingsComponent } from './components/pages/contabilitate/setari/facturi-chitante-settings/facturi-chitante-settings.component';
import { AddEditFatareFormComponent } from './components/common/rep-fat-modal/right-side/add-edit-fatare-form/add-edit-fatare-form.component';
import { AddEditReproductieFormComponent } from './components/common/rep-fat-modal/right-side/add-edit-reproductie-form/add-edit-reproductie-form.component';
import { AddEditMiscareFormComponent } from './components/common/rep-fat-modal/right-side/add-edit-miscare-form/add-edit-miscare-form.component';
import { AddUpdateHoldingAdminComponent } from './components/pages/holdings/components/add-update-holding-admin/add-update-holding-admin.component';
import { IdentificareFormBlockComponent } from './components/pages/holdings/components/add-update-holding/form-blocks/identificare-form-block/identificare-form-block.component';
import { AdminExpFormBlockComponent } from './components/pages/holdings/components/add-update-holding/form-blocks/admin-exp-form-block/admin-exp-form-block.component';
import { AdresaExpFormBlockComponent } from './components/pages/holdings/components/add-update-holding/form-blocks/adresa-exp-form-block/adresa-exp-form-block.component';
import { ContactFormBlockComponent } from './components/pages/holdings/components/add-update-holding/form-blocks/contact-form-block/contact-form-block.component';
import { DateBancareFormBlockComponent } from './components/pages/holdings/components/add-update-holding/form-blocks/date-bancare-form-block/date-bancare-form-block.component';
import { PedigreeFormBlockComponent } from './components/pages/animals/components/add-update-animal/form-blocks/pedigree-form-block/pedigree-form-block.component';
import { PerformantaFormBlockComponent } from './components/pages/animals/components/add-update-animal/form-blocks/performanta-form-block/performanta-form-block.component';
import { RegistruGenealogicFormBlockComponent } from './components/pages/animals/components/add-update-animal/form-blocks/registru-genealogic-form-block/registru-genealogic-form-block.component';
import { BuletineControlComponent } from './components/pages/holdings/components/holding/menu-tabs/cantariri/buletine-control/buletine-control.component';
import { IdentificareAnimalFormBlockComponent } from './components/pages/animals/components/add-update-animal/form-blocks/identificare-form-block/identificare-form-block.component';
import { AnimalPedigreeRgComponent } from './components/pages/animals/components/animal/menu-tabs/animal-pedigree-rg/animal-pedigree-rg.component';
import { AnimalPerformanceComponent } from './components/pages/animals/components/animal/menu-tabs/animal-performance/animal-performance.component';
import { ContractTabComponent } from './components/pages/holdings/components/holding/menu-tabs/documente/documente-tabs/contract-tab/contract-tab.component';
import { Anexa3TabComponent } from './components/pages/holdings/components/holding/menu-tabs/documente/documente-tabs/anexa3-tab/anexa3-tab.component';
import { Anexa8TabComponent } from './components/pages/holdings/components/holding/menu-tabs/documente/documente-tabs/anexa8-tab/anexa8-tab.component';
import { AddBuletinControlComponent } from './components/pages/holdings/components/holding/menu-tabs/cantariri/buletine-control/add-buletin-control/add-buletin-control.component';
import { EditBuletinControlComponent } from './components/pages/holdings/components/holding/menu-tabs/cantariri/buletine-control/edit-buletin-control/edit-buletin-control.component';
import { RegistruGenealogicTableComponent } from './components/pages/registru-genealogic/registru-genealogic-table/registru-genealogic-table.component';
import { RegistruGenealogicSearchComponent } from './components/pages/registru-genealogic/registru-genealogic-search/registru-genealogic-search.component';
import { AddUpdateFisaBonitareComponent } from './components/pages/animals/components/add-update-fisa-bonitare/add-update-fisa-bonitare.component';
import { CriteriiCantitativeSettingsComponent } from './components/pages/contabilitate/setari/criterii-cantitative-settings/criterii-cantitative-settings.component';
import { RaportIncasariTableComponent } from './components/pages/contabilitate/rapoarte/raport-incasari/raport-incasari-table/raport-incasari-table.component';
import { RaportFacturiEmiseTableComponent } from './components/pages/contabilitate/rapoarte/raport-facturi-emise/raport-facturi-emise-table/raport-facturi-emise-table.component';
import { RapoarteContaIncasariSearchComponent } from './components/common/table-search-modal/components/rapoarte-conta-incasari-search/rapoarte-conta-incasari-search.component';
import { RapoarteFacturiEmiseSearchComponent } from './components/common/table-search-modal/components/rapoarte-facturi-emise-search/rapoarte-facturi-emise-search.component';
import { AnimalComponent } from './components/pages/animals/components/animal/animal.component';
import { AnimalHistoryComponent } from './components/pages/animals/components/animal/menu-tabs/animal-history/animal-history.component';
import { AnimalDetailsComponent } from './components/pages/animals/components/animal/menu-tabs/animal-details/animal-details.component';
import { TableSearchModalComponent } from './components/common/table-search-modal/table-search-modal.component';
import { HoldingSearchComponent } from './components/common/table-search-modal/components/holding-search/holding-search.component';
import { AnimalSearchComponent } from './components/common/table-search-modal/components/animal-search/animal-search.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { HoldingComponent } from './components/pages/holdings/components/holding/holding.component';
import { UsersComponent } from './components/pages/users/users.component';
import { ReproductionComponent } from './components/pages/reproduction/reproduction.component';

import { AnimaleExploatatieComponent } from './components/pages/holdings/components/holding/menu-tabs/animale-exploatatie/animale-exploatatie.component';
import { CantaririComponent } from './components/pages/holdings/components/holding/menu-tabs/cantariri/cantariri.component';
import { DocumenteComponent } from './components/pages/holdings/components/holding/menu-tabs/documente/documente.component';
import { HartaComponent } from './components/pages/holdings/components/holding/menu-tabs/harta/harta.component';
import { BirthsComponent } from './components/pages/births/births.component';
import { BirthsTableComponent } from './components/pages/births/births-table/births-table.component';
import { BirthSearchComponent } from './components/common/table-search-modal/components/birth-search/birth-search.component';
import { RepFatModalComponent } from './components/common/rep-fat-modal/rep-fat-modal.component';

import { SlimscrollDirective } from './directives/slimscroll.directive';
import { AdvancedSortableDirective } from './directives/advanced-sortable.directive';

import { appRouteReducers } from './common/state/reducers/route.reducers';
import { appUserReducers } from './common/state/reducers/user.reducers';
import { countriesCitiesReducers } from './common/state/reducers/countries-cities.reducers';
import { animalsRacesReducers } from './common/state/reducers/animals.reducers';
import { holdingsReducers } from './common/state/reducers/holdings.reducers';
import { companyAdminsReducers } from './common/state/reducers/company-admins.reducers';
import { errorReducers } from './common/state/reducers/error.reducers';

import { SharingHelperDataService } from './common/services/sharing-helper-data.service';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppHttpInterceptor } from './http-interceptor';
import { DatePickerComponent } from './components/common/date-picker/date-picker.component';
import { ReproductionSearchComponent } from './components/common/table-search-modal/components/reproduction-search/reproduction-search.component';
import { ReproductionTableComponent } from './components/pages/reproduction/reproduction-table/reproduction-table.component';
import { MovesComponent } from './components/pages/moves/moves.component';
import { MovesTableComponent } from './components/pages/moves/moves-table/moves-table.component';
import { MoveSearchComponent } from './components/common/table-search-modal/components/move-search/move-search.component';
import { ContractTableComponent } from './components/pages/contracts/contract-table/contract-table.component';
import { ContractSearchComponent } from './components/common/table-search-modal/components/contract-search/contract-search.component';
import { AddUpdateContractComponent } from './components/pages/contracts/add-update-contract/add-update-contract.component';
import { FatariListComponent } from './components/common/rep-fat-modal/left-side/fatari-list/fatari-list.component';
import { MiscariListComponent } from './components/common/rep-fat-modal/left-side/miscari-list/miscari-list.component';
import { ReproductiiListComponent } from './components/common/rep-fat-modal/left-side/reproductii-list/reproductii-list.component';
import { OperatorsComponent } from './components/pages/operators/operators.component';
import { OperatorsTableComponent } from './components/pages/operators/operators-table/operators-table.component';
import { OperatorSearchComponent } from './components/common/table-search-modal/components/operator-search/operator-search.component';
import { AddUpdateOperatorComponent } from './components/pages/operators/add-update-operator/add-update-operator.component';
import { AddEditUserComponent } from './components/pages/users/add-edit-user/add-edit-user.component';
import { UsersTableComponent } from './components/pages/users/users-table/users-table.component';
import { IntrebariFrecventeComponent } from './components/dashboard/staticpages/intrebari-frecvente/intrebari-frecvente.component';
import { ContactComponent } from './components/dashboard/staticpages/contact/contact.component';
import { TermeniConditiiComponent } from './components/dashboard/staticpages/termeni-conditii/termeni-conditii.component';
import { environment } from 'src/environments/environment';
import { RegistruGenealogicComponent } from './components/pages/registru-genealogic/registru-genealogic.component';
import { CertificatSearchComponent } from './components/common/table-search-modal/components/certificat-search/certificat-search.component';
import { CertificatListSearchComponent } from './components/common/table-search-modal/components/certificatlist-search/certificatlist-search.component';
import { AdeverinteApartenentaComponent } from './components/pages/holdings/components/holding/menu-tabs/documente/documente-tabs/adeverinte-apartenenta-tab/adeverinte-apartenenta-tab.component';
import { AdeverinteApartenentaTableComponent } from './components/pages/allowances/adeverinte-apartenenta-table/adeverinte-apartenenta-table.component';
import { ViewAllowanceComponent } from './components/pages/allowances/view-allowance/view-allowance.component';
import { AddUpdateAllowanceComponent } from './components/pages/allowances/add-update-allowance/add-update-allowance.component';
import { AdeverintaSearchComponent } from './components/common/table-search-modal/components/adeverinta-search/adeverinta-search.component';
import { SettlementsComponent } from './components/pages/settlements/settlements.component';
import { SettlementsTableComponent } from './components/pages/settlements/settlements-table/settlements-table.component';
import { SettlementSearchComponent } from './components/common/table-search-modal/components/settlement-search/settlement-search.component';
import { EditSettlementComponent } from './components/pages/settlements/edit-settlement/edit-settlement.component';
import { ErrorComponent } from './components/common/error/error.component';
import { ViewReasonModalComponent } from './components/pages/registru-genealogic/view-reason-modal/view-reason-modal.component';
import { PozeAnimalFormBlockComponent } from './components/pages/animals/components/add-update-animal/form-blocks/poze-animal-form-block/poze-animal-form-block.component';
import { PerformanceComponent } from './components/pages/performance/performance.component';
import { PerformanceTableComponent } from './components/pages/performance/performance-table/performance-table.component';
import { PerformanceSearchComponent } from './components/common/table-search-modal/components/performance-search/performance-search.component';
import { SettingsPageComponent } from './components/pages/settings-page/settings-page.component';
import { ConfirmActionModalComponent } from './components/common/confirm-action-modal/confirm-action-modal.component';
import { filtersReducers } from './common/state/reducers/filters.reducers';
import { UmCriteriiSettingsComponent } from './components/pages/contabilitate/setari/um-criterii-settings/um-criterii-settings.component';
import { SetariActivitatiPageComponent } from './components/pages/contabilitate/setari/setari-activitati-page/setari-activitati-page.component';
import { ServiciiSettingsComponent } from './components/pages/contabilitate/setari/servicii-settings/servicii-settings.component';
import { FacturiComponent } from './components/pages/contabilitate/facturi/facturi.component';
import { FacturiTableViewComponent } from './components/pages/contabilitate/facturi/facturi-table-view/facturi-table-view.component';
import { FacturiSearchComponent } from './components/common/table-search-modal/components/facturi-search/facturi-search.component';
import { ActivitatiComponent } from './components/pages/contabilitate/activitati/activitati.component';
import { ActivitatiTableComponent } from './components/pages/contabilitate/activitati/activitati-table/activitati-table.component';
import { ActivitatiSearchComponent } from './components/common/table-search-modal/components/activitati-search/activitati-search.component';
import { FacturaManualaComponent } from './components/pages/contabilitate/facturi/factura-manuala/factura-manuala.component';
import { GenerareFacturiComponent } from './components/pages/contabilitate/facturi/generare-facturi/generare-facturi.component';
import { IncasariSearchComponent } from './components/common/table-search-modal/components/incasari-search/incasari-search.component';
import { IncasariComponent } from './components/pages/contabilitate/incasari/incasari.component';
import { IncasariTableComponent } from './components/pages/contabilitate/incasari/incasari-table/incasari-table.component';
import { IncasareComponent } from './components/pages/contabilitate/incasari/incasare/incasare.component';
import { RapoarteIncasariTableComponent } from './components/pages/contabilitate/incasari/rapoarte-incasari/rapoarte-incasari-table/rapoarte-incasari-table.component';
import { RapoarteIncasariComponent } from './components/pages/contabilitate/incasari/rapoarte-incasari/rapoarte-incasari.component';
import { RapoarteIncasariSearchComponent } from './components/common/table-search-modal/components/rapoarte-incasari-search/rapoarte-incasari-search.component';
import { RapoarteFacturiTableComponent } from './components/pages/contabilitate/facturi/rapoarte-facturi/rapoarte-facturi-table/rapoarte-facturi-table.component';
import { RapoarteFacturiComponent } from './components/pages/contabilitate/facturi/rapoarte-facturi/rapoarte-facturi.component';
import { RapoarteFacturiSearchComponent } from './components/common/table-search-modal/components/rapoarte-facturi-search/rapoarte-facturi-search.component';
import { RaportIncasariComponent } from './components/pages/contabilitate/rapoarte/raport-incasari/raport-incasari.component';
import { RaportFacturiEmiseComponent } from './components/pages/contabilitate/rapoarte/raport-facturi-emise/raport-facturi-emise.component';
import { RaportFacturiNeachitateComponent } from './components/pages/contabilitate/rapoarte/raport-facturi-neachitate/raport-facturi-neachitate.component';
import { RaportFacturiNeachitateTableComponent } from './components/pages/contabilitate/rapoarte/raport-facturi-neachitate/raport-facturi-neachitate-table/raport-facturi-neachitate-table.component';
import { RaportClientComponent } from './components/pages/contabilitate/rapoarte/raport-client/raport-client.component';
import { RaportClientTableComponent } from './components/pages/contabilitate/rapoarte/raport-client/raport-client-table/raport-client-table.component';
import { RaportJurnalComponent } from './components/pages/contabilitate/rapoarte/raport-jurnal/raport-jurnal.component';
import { RaportJurnalTableComponent } from './components/pages/contabilitate/rapoarte/raport-jurnal/raport-jurnal-table/raport-jurnal-table.component';
import { RapoarteFacturiNeachitateSearchComponent } from './components/common/table-search-modal/components/rapoarte-facturi-neachitate-search/rapoarte-facturi-neachitate-search.component';
import { RapoarteClientSearchComponent } from './components/common/table-search-modal/components/rapoarte-client-search/rapoarte-client-search.component';
import { RapoarteJurnalSearchComponent } from './components/common/table-search-modal/components/rapoarte-jurnal-search/rapoarte-jurnal-search.component';
import { RaportGeneralComponent } from './components/pages/raport-general/raport-general.component';
import { AdeverintaApiaComponent } from './components/pages/adeverinta-apia/adeverinta-apia.component';
import { AddEditAdeverintaApiaComponent } from './components/pages/adeverinta-apia/add-edit-adeverinta-apia/add-edit-adeverinta-apia.component';
import { EvaluareGeneticaComponent } from './components/pages/evaluare-genetica/evaluare-genetica.component';
import { IncadrareSectiuneComponent } from './components/pages/incadrare-sectiune/incadrare-sectiune.component';
import { RegistrulMonteFatariComponent } from './components/pages/registrul-monte-fatari/registrul-monte-fatari.component';
import { RegistrulMonteInHaremComponent } from './components/pages/registrul-monte-in-harem/registrul-monte-in-harem.component';
import { AnimaleRneComponent } from './components/pages/managementul-fermei/animale-rne/animale-rne.component';
import { ManagementFurajareComponent } from './components/pages/managementul-fermei/management-furajare/management-furajare.component';
import { ActiuniSanitarVeterinareComponent } from './components/pages/managementul-fermei/actiuni-sanitar-veterinare/actiuni-sanitar-veterinare.component';
import { PerformanteValorificareComponent } from './components/pages/managementul-fermei/performante-valorificare/performante-valorificare.component';
import { EvidenteFermaComponent } from './components/pages/managementul-fermei/evidente-ferma/evidente-ferma.component';
import { CalculeComponent } from './components/pages/managementul-fermei/calcule/calcule.component';
import { VenituriSubventiiComponent } from './components/pages/managementul-fermei/venituri-subventii/venituri-subventii.component';
import { DocumentIstoricComponent } from './components/pages/document-istoric/document-istoric.component';
import { ActeAditionaleComponent } from './components/pages/contracts/acte-aditionale/acte-aditionale.component';
import { AvizareTauriComponent } from './components/pages/avizare-tauri/avizare-tauri.component';
import { AddEditAvizareTauriComponent } from './components/pages/avizare-tauri/add-edit-avizare-tauri/add-edit-avizare-tauri.component';
import { CertificatSanitarComponent } from './components/pages/certificat-sanitar/certificat-sanitar.component';
import { AddEditCertificatSanitarComponent } from './components/pages/certificat-sanitar/add-edit-certificat-sanitar/add-edit-certificat-sanitar.component';
import { HoldingLocationsComponent } from './components/pages/holdings/components/add-update-holding/form-blocks/holding-locations/holding-locations.component';
import { UploadFisierComponent } from './components/common/upload-fisier/upload-fisier.component';
import { TemplateActeAditionaleComponent } from './components/pages/settings-page/template-acte-aditionale/template-acte-aditionale.component';
import { ReproductieFormBlockComponent } from './components/pages/animals/components/add-update-animal/form-blocks/reproductie-form-block/reproductie-form-block.component';
import { AdeverintaAjutorComponent } from './components/pages/adeverinta-ajutor/adeverinta-ajutor.component';
import { AddEditAdeverintaAjutorComponent } from './components/pages/adeverinta-ajutor/add-edit-adeverinta-ajutor/add-edit-adeverinta-ajutor.component';
import { SolicitariCertificateZootehniceComponent } from './components/pages/solicitari-certificate-zootehnice/solicitari-certificate-zootehnice.component';
import { AddEditSolicitariCertificateZootehniceComponent } from './components/pages/solicitari-certificate-zootehnice/add-edit-solicitari-certificate-zootehnice/add-edit-solicitari-certificate-zootehnice.component';
import { ProceseazaSolicitariCertificateZootehniceComponent } from './components/pages/solicitari-certificate-zootehnice/proceseaza/proceseaza.component';
import { CertificateZootehniceComponent } from './components/pages/certificate-zootehnice/certificate-zootehnice.component';
import { CaietDePasunatComponent } from './components/pages/caiet-de-pasunat/caiet-de-pasunat.component';

@NgModule({
  declarations: [
    ContainerComponent,
    LoginComponent,
    ForgotPasswordComponent,
    DashboardComponent,
    IntrebariFrecventeComponent,
    ContactComponent,
    TermeniConditiiComponent,
    FooterComponent,
    SidebarComponent,
    TopbarComponent,
    SlimscrollDirective,
    PreloaderComponent,
    HoldingsComponent,
    AnimalsComponent,
    AllowancesComponent,
    ContractsComponent,
    AdvancedSortableDirective,
    AddUpdateAnimalComponent,
    PedigreeFormBlockComponent,
    PerformantaFormBlockComponent,
    RegistruGenealogicFormBlockComponent,
    AddUpdateHoldingComponent,
    IdentificareFormBlockComponent,
    IdentificareAnimalFormBlockComponent,
    AdminExpFormBlockComponent,
    AdresaExpFormBlockComponent,
    ContactFormBlockComponent,
    DateBancareFormBlockComponent,
    TableSearchModalComponent,
    HoldingSearchComponent,
    AnimalSearchComponent,
    ResetPasswordComponent,
    HoldingComponent,
    AddUpdateHoldingAdminComponent,
    AnimaleExploatatieComponent,
    CantaririComponent,
    DocumenteComponent,
    HartaComponent,
    UsersComponent,
    AnimalComponent,
    AnimalHistoryComponent,
    AnimalPedigreeRgComponent,
    AnimalPerformanceComponent,
    AnimalDetailsComponent,
    ReproductionComponent,
    ReproductionSearchComponent,
    BirthsComponent,
    BirthsTableComponent,
    BirthSearchComponent,
    DatePickerComponent,
    ReproductionTableComponent,
    RepFatModalComponent,
    AddEditFatareFormComponent,
    AddEditReproductieFormComponent,
    MovesComponent,
    MovesTableComponent,
    MoveSearchComponent,
    ContractTableComponent,
    ContractSearchComponent,
    CertificatSearchComponent,
    ContractTabComponent,
    Anexa3TabComponent,
    Anexa8TabComponent,
    AddUpdateContractComponent,
    AddEditMiscareFormComponent,
    FatariListComponent,
    MiscariListComponent,
    ReproductiiListComponent,
    BuletineControlComponent,
    AddBuletinControlComponent,
    EditBuletinControlComponent,
    OperatorsComponent,
    OperatorsTableComponent,
    OperatorSearchComponent,
    AddUpdateOperatorComponent,
    AddEditUserComponent,
    UsersTableComponent,
    RegistruGenealogicComponent,
    RegistruGenealogicTableComponent,
    RegistruGenealogicSearchComponent,
    CertificatListSearchComponent,
    AdeverinteApartenentaTableComponent,
    AdeverinteApartenentaComponent,
    ViewAllowanceComponent,
    AddUpdateAllowanceComponent,
    AdeverintaSearchComponent,
    SettlementsComponent,
    SettlementsTableComponent,
    SettlementSearchComponent,
    EditSettlementComponent,
    ErrorComponent,
    ViewReasonModalComponent,
    AddUpdateFisaBonitareComponent,
    ChangeStatusReasonComponent,
    PozeAnimalFormBlockComponent,
    PerformanceComponent,
    PerformanceTableComponent,
    PerformanceSearchComponent,
    SettingsPageComponent,
    ConfirmActionModalComponent,
    SetariActivitatiPageComponent,
    UmCriteriiSettingsComponent,
    FacturiChitanteSettingsComponent,
    ServiciiSettingsComponent,
    FacturiComponent,
    FacturiTableViewComponent,
    FacturiSearchComponent,
    ActivitatiComponent,
    ActivitatiTableComponent,
    ActivitatiSearchComponent,
    CriteriiCantitativeSettingsComponent,
    FacturaManualaComponent,
    GenerareFacturiComponent,
    CriteriiCantitativeSettingsComponent,
    IncasariSearchComponent,
    IncasariComponent,
    IncasariTableComponent,
    IncasareComponent,
    RapoarteIncasariTableComponent,
    RapoarteIncasariComponent,
    RapoarteIncasariSearchComponent,
    RapoarteFacturiTableComponent,
    RapoarteFacturiComponent,
    RapoarteFacturiSearchComponent,
    RaportIncasariComponent,
    RaportIncasariTableComponent,
    RaportFacturiEmiseComponent,
    RaportFacturiEmiseTableComponent,
    RapoarteContaIncasariSearchComponent,
    RapoarteFacturiEmiseSearchComponent,
    RaportFacturiNeachitateComponent,
    RaportFacturiNeachitateTableComponent,
    RaportClientComponent,
    RaportClientTableComponent,
    RaportJurnalComponent,
    RaportJurnalTableComponent,
    RapoarteFacturiNeachitateSearchComponent,
    RapoarteClientSearchComponent,
    RapoarteJurnalSearchComponent,
    RaportGeneralComponent,
    AdeverintaApiaComponent,
    AddEditAdeverintaApiaComponent,
    EvaluareGeneticaComponent,
    IncadrareSectiuneComponent,
    RegistrulMonteFatariComponent,
    RegistrulMonteInHaremComponent,
    AnimaleRneComponent,
    ManagementFurajareComponent,
    ActiuniSanitarVeterinareComponent,
    PerformanteValorificareComponent,
    EvidenteFermaComponent,
    CalculeComponent,
    VenituriSubventiiComponent,
    DocumentIstoricComponent,
    ActeAditionaleComponent,
    AvizareTauriComponent,
    AddEditAvizareTauriComponent,
    CertificatSanitarComponent,
    AddEditCertificatSanitarComponent,
    HoldingLocationsComponent,
    UploadFisierComponent,
    TemplateActeAditionaleComponent,
    ReproductieFormBlockComponent,
    AdeverintaAjutorComponent,
    AddEditAdeverintaAjutorComponent,
    SolicitariCertificateZootehniceComponent,
    AddEditSolicitariCertificateZootehniceComponent,
    ProceseazaSolicitariCertificateZootehniceComponent,
    CertificateZootehniceComponent,
    CaietDePasunatComponent
  ],
  imports: [
    CommonModule,
    ACBCRRoutingModule,
    NgbDropdownModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    FormsModule,
    NgbAlertModule,
    NgbModule,
    StoreModule.forRoot({}),
    StoreModule.forFeature(
      'appData',
      {
        route: appRouteReducers,
        user: appUserReducers,
        countries_cities: countriesCitiesReducers,
        animals: animalsRacesReducers,
        holdings: holdingsReducers,
        filters: filtersReducers,
        company_admins: companyAdminsReducers,
        error: errorReducers
      },
    ),
    NgbPaginationModule,
    NgbTypeaheadModule,
    NgbTooltipModule,
    NgbModalModule,
    NgbDatepickerModule,
    NgSelectModule,
    UiSwitchModule,
    OrganizationChartModule,
    NgApexchartsModule,
    NgChartsModule,
    CKEditorModule
  ],
  exports: [ContainerComponent, HoldingsComponent],
  providers: [
    SharingHelperDataService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true
    },
    DecimalPipe
  ]
})
export class AcbcrModule { }
