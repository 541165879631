<ng-template #addAnimalModal let-modal="close('Cross click')">
  <div class="modal-header row" [ngSwitch]="modalType">
    <div class="col-xs-12 col-sm-6 col-md-6 custom-col">
      <h3 class="modal-title" *ngSwitchCase="'add'">
        Animal nou
      </h3>

      <h3 class="modal-title" *ngSwitchCase="'add-mother'">
        Mamă nouă - {{matricolChild}}
      </h3>

      <h3 class="modal-title" *ngSwitchCase="'add-father'">
        Tată nou - {{matricolChild}}
      </h3>

      <h3 class="modal-title" *ngSwitchCase="'edit'">
        Editează animal
      </h3>

      <h3 class="modal-title" *ngSwitchCase="'edit-mother'">
        Editează mamă - {{matricolChild}}
      </h3>

      <h3 class="modal-title" *ngSwitchCase="'edit-father'">
        Editează tată - {{matricolChild}}
      </h3>

      <h3 class="modal-title" *ngSwitchCase="'view'">
        Vizualizează animal
      </h3>

      <h3 class="modal-title" *ngSwitchDefault>
        Animal nou
      </h3>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-6 text-right custom-col">
      <div class="btn-group custom-modal-btn">
        <button
          class="btn btn-sm btn-dark"
          (click)="(modal); notifyCloseModal()"
          autofocus="false"
        >
          <span *ngIf="changedButtonText || disableAllFields; else anuleazaTxt">Închide</span>
          <ng-template #anuleazaTxt>
            <span>Anulează</span>
          </ng-template>
        </button>
        <button
          *ngIf="modalType != 'view'"
          type="submit"
          class="btn btn-sm btn-success"
          (click)="onSubmitted(true)"
          [ngClass]="{ 'disabled': disabledSubmit }"
        >
          <span *ngIf="modalType === 'add'; else editTxt">
            Salvează noul animal
          </span>
          <ng-template #editTxt>
            <span *ngIf="modalType === 'edit'; else parentTxt">
              Actualizează animal
            </span>
          </ng-template>
          <ng-template #parentTxt>
            <span>
              Adaugă părinte
            </span>
          </ng-template>
        </button>
      </div>
    </div>
  </div>

  <div class="d-flex overflow-auto">
    <div class="modal-sidebar d-none d-xl-block">
      <ul class="modal-menu">
        <li
          (click)="scrollToElement('#identificareAnimal')"
          [ngClass]="{ active: elemName.includes('identificareAnimal') }"
        >
          Identificare animal
        </li>

        <li
          *ngIf="animalForm.controls.sex.value === '1'"
          (click)="scrollToElement('#reproductie')"
          [ngClass]="{ active: elemName.includes('reproductie') }"
        >
          Reproducție
        </li>

        <li
          (click)="scrollToElement('#pedigree')"
          [ngClass]="{ active: elemName.includes('pedigree') }"
        >
          Pedigree
        </li>

        <li
          (click)="scrollToElement('#performanta')"
          [ngClass]="{ active: elemName.includes('performanta') }"
        >
          Performanță
        </li>

        <li
          (click)="scrollToElement('#registruGenealogic')"
          [ngClass]="{ active: elemName.includes('registruGenealogic') }"
        >
          Registru genealogic
        </li>

        <li
          (click)="scrollToElement('#pozeAnimal')"
          [ngClass]="{ active: elemName.includes('pozeAnimal') }"
        >
          Poze animal
        </li>
      </ul>
    </div>

    <div class="modal-body">
      <div class="container-fluid">
        <div class="">
          <form [formGroup]="animalForm">
            <!-- Identificare section -->
            <app-identificare-animal-form-block
              [animalForm]="animalForm"
              [evaluareGenetica]="evaluareGenetica"
              [areFatare]="areFatare"
              [submitted]="submitted"
              [modalType]="modalType"
              [errorCodTaur]="errorCodTaur"
              [invalidAnimalSex]="invalidAnimalSex"
              [disableAllFields]="disableAllFields"
              (selectedParent)="populateFields($event)"
              (updateFields)="updatePedigreeTree($event)"
              (closeUpdateModal)="closeUpdateModal()"
            >
            </app-identificare-animal-form-block>

            <app-reproductie-form-block
              [animalForm]="animalForm"
              [submitted]="submitted"
              [disableAllFields]="disableAllFields"
            ></app-reproductie-form-block>

            <!-- Pedigree section -->
            <app-pedigree-form-block
              [animalForm]="animalForm"
              [evaluareGenetica]="evaluareGenetica"
              [areFatare]="areFatare"
              [submitted]="submitted"
              [updatePedigreeTree]="updatedPedigreeTree"
              [disableAllFields]="disableAllFields"
              (adaugaRasa)="addRemoveRase($event)"
            >
            </app-pedigree-form-block>

            <!-- Performance section -->
            <app-performanta-form-block
              [animalForm]="animalForm"
              [evaluareGenetica]="evaluareGenetica"
              [areFatare]="areFatare"
              [modalType]="modalType"
              [submitted]="submitted"
              [disableAllFields]="disableAllFields"
              (openBonitareModal)="bonitareModalChanged($event)"
            >
            </app-performanta-form-block>

            <!-- Registru genealogic section -->
            <app-registru-genealogic-form-block
              [animalForm]="animalForm"
              [modalType]="modalType"
              [submitted]="submitted"
              [disableAllFields]="disableAllFields"
              (disableSubmit)="disableSubmitButton($event)"
              (closeUpdateModal)="closeUpdateModal()"
            ></app-registru-genealogic-form-block>

            <app-poze-animal-form-block
              [animalForm]="animalForm"
              [modalType]="modalType"
              [submitted]="submitted"
              [disableAllFields]="disableAllFields"
              (deletedImage)="deletedImage($event)"
            ></app-poze-animal-form-block>

          </form>
        </div>
      </div>
    </div>
  </div>

</ng-template>
