import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CaietDePasunatService {
  private baseUrl = environment.API_URL;

  constructor(
    private http: HttpClient
  ) { }

  CaietDePasunatDownload(formData) {
    return this.http.post(
      `${this.baseUrl}/caiet_de_pasunat/`,
        formData,
        {
          responseType: 'blob'
        }
      );
  }

  CaietDePasunatStats(formData) {
    return this.http.post(
      `${this.baseUrl}/caiet_de_pasunat/`,
        formData
      );
  }
}
