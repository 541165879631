<div
    *ngIf="isAdmin || isOperator"
    class="col-12"
>
    <h1 class="mb-3">Caiet de pășunat</h1>
</div>

<div
    *ngIf="isAdmin || isOperator"
    class="col-sm-12 col-xl-6"
>

    <div class="row mb-2">
        <input
            #fileUpload
            type="file"
            accept=".pdf"
            class="file-input"
            style="display: none"
            (change)="selectatFisierAnimale($event)"
        />

        <button
        class="btn btn-info"
        (click)="fileUpload.click()"
        [disabled]="disabledAlegeFisier"
        >
            Alege fișierul pdf
        </button>
    </div>

    <div *ngIf="fisierAnimale?.name" class="row mb-2">
        {{ fisierAnimale?.name }}
    </div>

    <form [formGroup]="pasunatForm">
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                    <label>
                        Data ieșire pășunat
                    </label>

                    <app-date-picker
                        [selectedDate]="pasunatForm.controls.data_iesire.value"
                        (dateChanged)="pasunatForm.controls.data_iesire.setValue($event)">
                    </app-date-picker>
                </div>
            </div>

            <div class="col-sm-12 col-md-6">
                <div class="form-group mb-3">
                <label>
                    Nr. ore pășunat
                </label>

                <input
                    type="number"
                    formControlName="nr_ore_pasunat"
                    class="form-control"
                />
                </div>
            </div>
        </div>

        <ng-container *ngFor="let parcelaForm of parcele.controls; let index = index">
            <ng-container [formGroup]="parcelaForm">
                <div class="row mb-3">
                    <div class="col-sm-12 col-md-3 mr-2">
                        <label for="nr_bloc">
                            Nr. bloc fizic
                        </label>

                        <input
                            type="text"
                            formControlName="nr_bloc"
                            class="form-control"
                        />
                    </div>

                    <div class="col-sm-12 col-md-3 mr-2">
                        <label for="nr_parcela">
                            Nr. parcelă
                        </label>

                        <input
                            type="text"
                            formControlName="nr_parcela"
                            class="form-control"
                        />
                    </div>

                    <div class="col-sm-12 col-md-3 mr-2">
                        <label for="suprafata_parcela">
                            Suprafața totală a parcelei
                        </label>

                        <input
                            type="text"
                            formControlName="suprafata_parcela"
                            class="form-control"
                        />
                    </div>

                    <div>
                        <label>&nbsp;</label>
                        <div class="border-0 p-0">
                            <i
                                class="mdi mdi-close-circle text-danger fs-3 cursor-pointer"
                                ngbTooltip="Șterge locația"
                                placement="bottom"
                                (click)="stergeParcela(index)"
                            ></i>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <div class="row mb-3">
            <div class="col-sm-12">
                <button
                    class="btn btn-success btn-xs"
                    type="button"
                    (click)="adaugaParcela()"
                >
                    <i class="mdi mdi-plus-circle fs-4 align-middle"></i>
                    Adaugă parcela
                </button>
            </div>
        </div>

    </form>

    <div class="separator"></div>

    <div class="row mt-3 mb-3">
        <button
            class="btn btn-blue"
            (click)="clickGenereaza()"
            [disabled]="disabledAlegeFisier"
        >
            Generează
        </button>

        <span *ngIf="disabledAlegeFisier" class="btn text-info">Procesare ...</span>
    </div>

    <div *ngIf="stats" class="h4">
        <div class="mb-1">Data intrare {{ stats['data_intrare'] }}</div>
        <div class="mb-1">Data ieșire {{ stats['data_iesire'] }}</div>
        <div class="mb-1">Nr. zile
            <span [ngClass]="{
                'text-danger': stats['nr_zile'] < 120,
                'text-success': stats['nr_zile'] >= 120,
            }">{{ stats['nr_zile'] }}</span>
        </div>

        <div class="text-danger">{{ stats['eroare_uvm_per_ha'] }}</div>
    </div>

</div>
