<!-- ========== Left Sidebar Start ========== -->
<ng-template #contentTemplate>
  <!--- Sidemenu -->
  <div id="sidebar-menu" class="d-print-none">
    <ul class="acbcr-menu" #sideMenu>
      <li *ngIf="isContabil" class="menu-title">Contabilitate</li>

      <li *ngIf="isContabil">
        <a
          href="javascript: void(0);"
          class="waves-effect"
          aria-expanded="false"
        >
          <i class="mdi mdi-settings"></i>
          <span> SETĂRI </span>
          <span class="menu-arrow"></span>
        </a>

        <ul class="nav-second-level collapse" aria-expanded="false">
          <li>
            <a
              routerLink="/contabilitate/setari-um"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              UM
            </a>
          </li>

          <li>
            <a
              routerLink="/contabilitate/setari-chitante-facturi"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Facturi și chitanțe
            </a>
          </li>

          <li>
            <a
              routerLink="/contabilitate/setari-activitati"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Activități
            </a>
          </li>

          <li>
            <a
              routerLink="/contabilitate/setari-servicii"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Servicii în cadrul activității
            </a>
          </li>

          <li>
            <a
              routerLink="/contabilitate/criterii-cantitative"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Criterii cantitative
            </a>
          </li>
        </ul>
      </li>

      <li routerLinkActive="active" *ngIf="isContabil">
        <a
          routerLink="/contabilitate/activitati"
          routerLinkActive="active"
          class="waves-effect"
        >
          <i class="mdi mdi-dns"></i>
          <span>ACTIVITĂȚI </span>
        </a>
      </li>

      <li *ngIf="isContabil">
        <a
          href="javascript: void(0);"
          class="waves-effect"
          aria-expanded="false"
        >
          <i class="mdi mdi-script"></i>
          <span> FACTURI </span>
          <span class="menu-arrow"></span>
        </a>

        <ul class="nav-second-level collapse" aria-expanded="false">
          <li>
            <a
              routerLink="/contabilitate/vizualizare-facturi"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Vizualizare facturi
            </a>
          </li>

          <!-- <li>
            <a
              href="javascript: void(0);"
              class="waves-effect"
              aria-expanded="false"
            >
              <span> Rapoarte </span>
              <span class="menu-arrow"></span>
            </a>

                <ul class="nav-second-level collapse">
                  <li>
                    <a
                      routerLink="/contabilitate/raport-incasari"
                      routerLinkActive="active"
                      class="side-nav-link-ref"
                    >
                      Raport facturi emise
                    </a>
                  </li>
                </ul>
          </li> -->
        </ul>
      </li>

      <li *ngIf="isContabil">
        <a
          href="javascript: void(0);"
          class="waves-effect"
          aria-expanded="false"
        >
          <i class="mdi mdi-bank"></i>
          <span> ÎNCASĂRI </span>
          <span class="menu-arrow"></span>
        </a>

        <ul class="nav-second-level collapse" aria-expanded="false">
          <li>
            <a
              routerLink="/contabilitate/incasare"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Încasare
            </a>
          </li>

          <li>
            <a
              routerLink="/contabilitate/chitante"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Vizualizare chitanțe
            </a>
          </li>

          <!-- <li>
            <a
              routerLink="/contabilitate/raport-incasari"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Rapoarte
            </a>
          </li> -->
        </ul>
      </li>

      <li *ngIf="isContabil">
        <a
          href="javascript: void(0);"
          class="waves-effect"
          aria-expanded="false"
        >
          <i class="mdi mdi-monitor-dashboard"></i>
          <span> RAPOARTE </span>
          <span class="menu-arrow"></span>
        </a>

        <ul class="nav-second-level collapse" aria-expanded="false">
          <li>
            <a
              routerLink="/contabilitate/raport-incasari"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Încasări
            </a>
          </li>
          <li>
            <a
              routerLink="/contabilitate/raport-facturi-emise"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Facturi emise
            </a>
          </li>
          <li>
            <a
              routerLink="/contabilitate/raport-facturi-neachitate"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Facturi neachitate
            </a>
          </li>
          <li>
            <a
              routerLink="/contabilitate/raport-client"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Raport client
            </a>
          </li>
          <li>
            <a
              routerLink="/contabilitate/raport-jurnal"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Jurnal
            </a>
          </li>
        </ul>
      </li>

      <li class="module_title mb-1">I)<span> PROGRAM DE AMELIORARE</span></li>

      <li routerLinkActive="active">
        <a
          *ngIf="linkExploatatie"
          [routerLink]="linkExploatatie"
          routerLinkActive="active"
          class="waves-effect text-nowrap"
        >
          <i class="mdi mdi-home"></i>
          <span>EXPLOATAȚIA MEA</span>
        </a>

        <a
          *ngIf="!linkExploatatie"
          routerLink="/dashboard/exploatatii"
          routerLinkActive="active"
          class="waves-effect text-nowrap"
        >
          <i class="mdi mdi-home"></i>
          <span>EXPLOATAȚII</span>
        </a>
      </li>

      <li routerLinkActive="active">
        <a
          routerLink="/dashboard/animale"
          routerLinkActive="active"
          class="waves-effect text-nowrap"
        >
          <i class="mdi mdi-cow"></i>
          <span>ANIMALE</span>
        </a>
      </li>

      <li routerLinkActive="active">
        <a
          routerLink="/dashboard/registru-genealogic"
          routerLinkActive="active"
          class="waves-effect text-nowrap"
        >
          <i class="mdi mdi-file-multiple"></i>
          <span>REGISTRU GENEALOGIC</span>
        </a>
      </li>

      <li routerLinkActive="active">
        <a href="javascript: void(0);" class="waves-effect">
          <i class="fe-file-text"></i>
          <span> DOCUMENTE </span>
          <span class="menu-arrow"></span>
        </a>

        <ul class="nav-second-level collapse" aria-expanded="false">
          <li routerLinkActive="active">
            <a
              routerLink="/dashboard/solicitari-certificate-zootehnice"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Solicitări certificate zootehnice
            </a>
          </li>

          <li routerLinkActive="active">
            <a
              routerLink="/dashboard/certificate-zootehnice"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Certificate zootehnice
            </a>
          </li>

          <li routerLinkActive="active">
            <a
              routerLink="/dashboard/adeverinte"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Adeverințe de apartenență
            </a>
          </li>

          <li *ngIf="isAdmin || isOperator || isFermier" routerLinkActive="active">
            <a
              routerLink="/dashboard/adeverinta-apia"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Adeverință APIA
            </a>
          </li>

          <li *ngIf="isAdmin || isOperator || isFermier" routerLinkActive="active">
            <a
              routerLink="/dashboard/adeverinta-ajutor"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Adeverință Ajutor Ucraina
            </a>
          </li>

          <li *ngIf="!isAutoritateJudeteana" routerLinkActive="active">
            <a
              routerLink="/dashboard/contracte"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Contracte
            </a>
          </li>

          <li routerLinkActive="active">
            <a
              routerLink="/dashboard/activitate-documente"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Activitate
            </a>
          </li>

          <li routerLinkActive="active">
            <a
              routerLink="/dashboard/certificat-sanitar"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Certificat Sanitar
            </a>
          </li>

          <li routerLinkActive="active">
            <a
              routerLink="/dashboard/avizare-tauri"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              <span>Avizare Tauri</span>
            </a>
          </li>

          <li *ngIf="isSuperAdmin" routerLinkActive="active">
            <a
              routerLink="/dashboard/registrul_de_monte_si_fatari"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Registrul de monte și fatari
            </a>
          </li>

          <li *ngIf="isSuperAdmin" routerLinkActive="active">
            <a
              routerLink="/dashboard/registrul_de_monte_in_harem"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Registrul de monte în harem
            </a>
          </li>
        </ul>
      </li>

      <li routerLinkActive="active">
        <a
          routerLink="/dashboard/evaluare-genetica"
          routerLinkActive="active"
          class="waves-effect text-nowrap"
        >
          <i class="mdi mdi-dna"></i>
          <span>EVALUARE GENETICĂ</span>
        </a>
      </li>

      <li routerLinkActive="active">
        <a
          href="javascript: void(0);"
          class="waves-effect"
          routerLink="/dashboard/reproductie"
          routerLinkActive="active"
        >
          <i class="mdi mdi-reproduction"></i>
          <span> REPRODUCȚIE </span>
        </a>
      </li>

      <li routerLinkActive="active">
        <a
          routerLink="/dashboard/fatari"
          routerLinkActive="active"
          class="waves-effect"
        >
          <i class="mdi mdi-donkey"></i>
          <span> FĂTĂRI </span>
        </a>
      </li>

      <li routerLinkActive="active">
        <a
          routerLink="/dashboard/miscari"
          routerLinkActive="active"
          class="waves-effect"
        >
          <i class="mdi mdi-clipboard-arrow-right"></i>
          <span> MIȘCĂRI </span>
        </a>
      </li>

      <li routerLinkActive="active">
        <a
          routerLink="/dashboard/testarea-performantelor"
          routerLinkActive="active"
          class="waves-effect"
        >
          <i class="mdi mdi mdi-chart-areaspline"></i>
          <span> PERFORMANȚE </span>
        </a>
      </li>

      <li *ngIf="isAdmin || isOperator" routerLinkActive="active">
        <a href="javascript: void(0);" class="waves-effect">
          <i class="mdi mdi-monitor-dashboard"></i>
          <span> RAPOARTE </span>
          <span class="menu-arrow"></span>
        </a>

        <ul class="nav-second-level collapse" aria-expanded="false">
          <li routerLinkActive="active">
            <a
              routerLink="/dashboard/raport_indicatori"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Raport Indicatori de producție și reproducție - carne
            </a>
          </li>

          <li routerLinkActive="active">
            <a
              routerLink="/dashboard/raport_femele"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Raport Femele - Reproducție
            </a>
          </li>

          <li routerLinkActive="active">
            <a
              routerLink="/dashboard/raport_descendenti"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Raport Descendenți
            </a>
          </li>

          <li routerLinkActive="active">
            <a
              routerLink="/dashboard/raport_animale"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Raport Animale
            </a>
          </li>

          <li routerLinkActive="active">
            <a
              routerLink="/dashboard/raport_teste_adn"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Raport Teste ADN
            </a>
          </li>

          <li routerLinkActive="active">
            <a
              routerLink="/dashboard/raport_informare_lunara_cppc"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Raport Informare Lunară CPPC
            </a>
          </li>

          <li routerLinkActive="active">
            <a
              routerLink="/dashboard/raport_atentionari_fermier"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Raport Atenționări Pentru Fermier
            </a>
          </li>

          <li routerLinkActive="active">
            <a
              routerLink="/dashboard/raport_grafic_cppc"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Raport Grafic CPPC
            </a>
          </li>

          <li routerLinkActive="active">
            <a
              routerLink="/dashboard/raport_anz"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Raport ANZ
            </a>
          </li>

          <li routerLinkActive="active">
            <a
              routerLink="/dashboard/raport_coeficientul_de_izolare_reproductiva"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Raport Coeficientul de Izolare Reproductivă
            </a>
          </li>

          <li routerLinkActive="active">
            <a
              routerLink="/dashboard/raport_consangvinizare"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Raport Consangvinizare
            </a>
          </li>

          <li routerLinkActive="active">
            <a
              routerLink="/dashboard/raport_durata_medie_de_exploatare"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Raport Durata Medie de Exploatare
            </a>
          </li>

          <li routerLinkActive="active">
            <a
              routerLink="/dashboard/raport_exploatatii"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Raport Exploatații
            </a>
          </li>

          <li routerLinkActive="active">
            <a
              routerLink="/dashboard/raport_autorizatii_monta"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Raport Autorizații Montă
            </a>
          </li>

          <li routerLinkActive="active">
            <a
              routerLink="/dashboard/raport_date_colectate"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Raport Date Colectate
            </a>
          </li>

          <li routerLinkActive="active">
            <a
              routerLink="/dashboard/caiet_de_pasunat"
              routerLinkActive="active"
              class="side-nav-link-ref"
            >
              Caiet de pășunat
            </a>
          </li>
        </ul>
      </li>

      <li routerLinkActive="active" *ngIf="isAdmin">
        <a
          routerLink="/dashboard/incadrare-sectiune"
          routerLinkActive="active"
          class="waves-effect text-nowrap"
        >
          <i class="mdi mdi-box-shadow"></i>
          <span>ÎNCADRARE SECȚIUNE</span>
        </a>
      </li>

      <li routerLinkActive="active" *ngIf="isAdmin">
        <a
          routerLink="/dashboard/operatori-inseminare"
          routerLinkActive="active"
          class="waves-effect"
        >
          <i class="mdi mdi-worker"></i>
          <span>OPERATORI </span>
        </a>
      </li>

      <li routerLinkActive="active" *ngIf="isAdmin">
        <a
          href="javascript: void(0);"
          class="waves-effect"
          routerLink="/dashboard/utilizatori"
          routerLinkActive="active"
        >
          <i class="mdi mdi-account-multiple"></i>
          <span> UTILIZATORI </span>
        </a>
      </li>

      <li routerLinkActive="active" *ngIf="isAdmin">
        <a
          href="javascript: void(0);"
          class="waves-effect"
          routerLink="/dashboard/setari"
          routerLinkActive="active"
        >
          <i class="mdi mdi-settings"></i>
          <span> SETĂRI </span>
        </a>
      </li>
    </ul>

    <ul *ngIf="isSuperAdmin" class="acbcr-menu" #sideMenu>
      <li class="module_title mt-3">II)<span> MANAGEMENTUL FERMEI</span></li>

      <li routerLinkActive="active">
        <a
          routerLink="/dashboard/managementul-fermei/animale-rne"
          routerLinkActive="active"
          class="waves-effect text-nowrap"
        >
          <span>ANIMALE RNE</span>
        </a>
      </li>

      <li routerLinkActive="active">
        <a
          routerLink="/dashboard/managementul-fermei/management-furajare"
          routerLinkActive="active"
          class="waves-effect text-nowrap"
        >
          <span>MANAGEMENT FURAJARE</span>
        </a>
      </li>

      <li routerLinkActive="active">
        <a
          routerLink="/dashboard/managementul-fermei/actiuni-sanitar-veterinare"
          routerLinkActive="active"
          class="waves-effect text-nowrap"
        >
          <span>ACTIUNI SANITAR VETERINARE</span>
        </a>
      </li>

      <li routerLinkActive="active">
        <a
          routerLink="/dashboard/managementul-fermei/performante-valorificare"
          routerLinkActive="active"
          class="waves-effect text-nowrap"
        >
          <span>PERFORMANTE/VALORIFICARE</span>
        </a>
      </li>

      <li routerLinkActive="active">
        <a
          routerLink="/dashboard/managementul-fermei/evidente-ferma"
          routerLinkActive="active"
          class="waves-effect text-nowrap"
        >
          <span>EVIDENTE FERMA</span>
        </a>
      </li>

      <li routerLinkActive="active">
        <a
          routerLink="/dashboard/managementul-fermei/calcule"
          routerLinkActive="active"
          class="waves-effect text-nowrap"
        >
          <span>CALCULE</span>
        </a>
      </li>

      <li routerLinkActive="active">
        <a
          routerLink="/dashboard/managementul-fermei/venituri-subventii"
          routerLinkActive="active"
          class="waves-effect text-nowrap"
        >
          <span>VENITURI/SUBVENTII</span>
        </a>
      </li>

    </ul>
  </div>
  <!-- End Sidebar -->

  <div class="clearfix"></div>
</ng-template>

<div class="left-side-menu">
  <div class="slimscroll-menu" appSlimScroll *ngIf="!isCondensed">
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </div>

  <div class="slimscroll-menu" *ngIf="isCondensed">
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </div>
</div>
<!-- Left Sidebar End -->
